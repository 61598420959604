import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { ApiService } from '../../../services/api.service';
import { ValidationService } from '../../../services/validation.service';
import { PasswordBoxComponent } from '../../atoms/password-box/password-box.component';

@Component({
    selector: '.p-renew-password'
})
export class RenewPasswordComponent extends ComponentBase<HTMLElement> {

    @Input('token')
    private _token: string = null;
    
    @ChildRef('#txtPassword')
    private _txtPassword: PasswordBoxComponent = null;
    @ChildRef('#txtRepeatPassword')
    private _txtRepeatPassword: PasswordBoxComponent = null;
    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _validationService: ValidationService,
        private _scrollService: ScrollService) {
        super(node);       
    }   

    public onInit(): void {
        this.registerFormFieldInput('.p-renew-password__form', field => {
            if (this._txtPassword.value && this._txtRepeatPassword.value) {
                this.validate();
            }
            else {
                this.validateField(field);
            }
        });
    }

    @KeyDownListener(Key.Enter, '#txtPassword')
    public onPasswordEnter(): void {
        this.onSend();    
    }

    @KeyDownListener(Key.Enter, '#txtRepeatPassword')
    public onRepeatPasswordEnter(): void {
        this.onSend();      
    }
    
    @ClickListener('.p-renew-password__send button') 
    public async onSend(): Promise<void> {
        if (this.validate()) {
            const { password } = this.getFormData('.p-renew-password__form');
            try {
                await this._apiService.post('/api/v1/user/renew-password', { 
                    body: {
                        password,
                        token: this._token
                    }
                });
                this.addClass('p-renew-password--changed');
            }
            catch (e: any) {
                if (e.statusCode == 400) {
                    this._validationService.showErrors('.p-renew-password__form', JSON.parse(e.data?.message));
                    // this.setErrors('.p-renew-password__errors', e.data?.message || e.statusMessage || e.statusCode);
                    this._scrollService.scrollToTop();
                }
                else if (e.statusCode == 409) {
                    this.showUnexpectedError({ message: e.data?.message });
                }
                else {
                    this.redirect('/u/retry-forgot-password')
                }
            }
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.p-renew-password__form')
        const errors: DictionaryObject<string[]> = this._validationService.validateRenewPassword(data, data);
        this._validationService.showErrors('.p-renew-password__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateRenewPassword(field, this.getFormData('.p-renew-password__form'));
        this._validationService.showErrors('.p-renew-password__form', errors);
    }
}