import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.p-forgot-parental-pin'
})
export class ForgotParentalPinComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _sessionService: SessionService) {
        super(node);       
    }   
    
    @ClickListener('.p-forgot-parental-pin__send button') 
    public async onSend(): Promise<void> {
        this.send(true);
    }

    @ClickListener('.p-forgot-parental-pin__resend button') 
    public async onResend(): Promise<void> {
        this.send();
    }

    @KeyDownListener(Key.Enter, '#txtEmail')
    public onEmailEnter(): void {
        this.send(true);    
    }

    public onInit(): void {
        super.onInit();
        this.addClass(this._sessionService.isSigned ? 'p-forgot-parental-pin--signed': 'p-forgot-parental-pin--unsigned');
    }

    private async send(isFirst?: boolean): Promise<void> {
        const { email } = this.getFormData('.p-forgot-parental-pin__email');
        if (email) {
            await this._apiService.post('/api/v1/user/forgot-parental-pin', { 
                body: {
                    email
                }
            });
            if (isFirst) {
                this.setInnerHTML(this.getInnerHTML('.p-forgot-parental-pin__title2').format(email), '.p-forgot-parental-pin__title2');
                this.addClass('p-forgot-parental-pin--sent');
            }
        }
    }
}