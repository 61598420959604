import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { ApiService } from '../../../services/api.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
    selector: '.p-forgot-password'
})
export class ForgotPasswordComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement,
        private _validationService: ValidationService,
        private _apiService: ApiService) {
        super(node);       
    }   
    
    @ClickListener('.p-forgot-password__send button') 
    public async onSend(): Promise<void> {
        this.send(true);
    }

    @ClickListener('.p-forgot-password__resend button') 
    public async onResend(): Promise<void> {
        this.send();
    }

    @KeyDownListener(Key.Enter, '#txtEmail')
    public onEmailEnter(): void {
        this.send(true);    
    }

    private async send(isFirst?: boolean): Promise<void> {
        const { email } = this.getFormData('.p-forgot-password__email');
        if (email) {
            try {
                await this._apiService.post('/api/v1/user/forgot-password', {
                    body: {
                        email
                    }
                });
                if (isFirst) {
                    this.setInnerHTML(this.getInnerHTML('.p-forgot-password__title2').format(email), '.p-forgot-password__title2');
                    this.addClass('p-forgot-password--sent');
                }
            } catch (e: any) {
                if (e.statusCode === 400) {
                    this._validationService.showErrors('.p-forgot-password__email', { email: [USER_LOCALE.emailFormatError] });
                    this.setErrors('.p-forgot-password__errors', USER_LOCALE.emailFormatError);
                }
            }
        }
    }
}