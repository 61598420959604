import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { Session, SessionService } from '../../../services/session.service';
import { ApiService } from '../../../services/api.service';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { ValidationService } from '../../../services/validation.service';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { SessionStorageService } from '../../../../../lib/web/services/session-storage.service';
import { DataEventService } from '../../../services/data-event.service';

@Component({
    selector: '.p-sign-in'
})
export class SignInComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement, 
        private _apiService: ApiService, 
        private _sessionService: SessionService, 
        private _sessionStorageService: SessionStorageService,
        private _scrollService: ScrollService,
        private _validationService: ValidationService,
        private _dataEventService: DataEventService) {
        super(node);        
    }    

    public onInit(): void {
        this.registerFormFieldInput('.p-sign-in__form', field => this.validateField(field));
        this.focus('#txtEmail');
        this._dataEventService.loginTry();
    }

    @KeyDownListener(Key.Enter, '#txtEmail')
    public onEmailEnter(): void {
        this.signIn();
    }

    @KeyDownListener(Key.Enter, '#txtPassword')
    public onPasswordEnter(): void {
        this.signIn();
    }
   
    @ClickListener('.p-sign-in__send')
    public onSend(): void {
        if (this.validate()) {
            this.signIn();
        }
        else {
            this._scrollService.scrollToTop();
        }
    }

    private async signIn(): Promise<void> {
        const { email, password } = this.getFormData('.p-sign-in__form');
        try {
            if (email && password) {
                this._dataEventService.loginSend('email');
                const { user, authToken, redirectUrl } = await this._apiService.post('/api/v1/user/sign-in', {
                    body: {
                        email,
                        password
                    }
                });                
                if (user && authToken) {
                    this._sessionService.set({
                        user,
                    }); 
                }      
                this._dataEventService.login(user.id, 'email');
                window.dispatchEvent(new CustomEvent('login'));
                const redirectAfterSignIn: string = this._sessionStorageService.get('redirect-after-sign-in');
                this.redirect(redirectUrl || redirectAfterSignIn || '/');
            }
        }
        catch (e: any) {
            if (e.statusCode == 401) {
                if (e.data?.message == 'E-VERIFY-USER') {
                    this.redirect(`/u/verify-account?email=${email}`);
                }
                if (e.data?.message == 'E-DISABLED-USER') {
                    this.setErrors('.p-sign-in__errors', USER_LOCALE.disabledUserMessage.format(CONFIG.WEB_CONTACT_URL[USER_LOCALE.localeId]));
                }  
                if (e.data?.message == 'E-CHANGE-PASSWORD') {
                    const changePasswordToken: string = e.data?.data?.changePasswordToken;
                    this.redirect(`/u/require-change-password/${changePasswordToken}`);
                }               
                else {
                    this.setErrors('.p-sign-in__errors', USER_LOCALE.signInFailed);
                }
            }
            else if (e.statusCode == 403) {
                this.setErrors('.p-sign-in__errors', e.data?.message || e.statusMessage || e.statusCode);
            }
            else {
                this.showUnexpectedError(e);
            }            
            this._scrollService.scrollToTop();
            if (e.statusCode != 401 || e.data?.message != 'E-VERIFY-USER') {
                this._dataEventService.loginFail('email');
            }
        }
    }

    public validate(): boolean {
        this.setErrors('.p-sign-in__errors');
        const data: any = this.getFormData('.p-sign-in__form')
        const errors: DictionaryObject<string[]> = this._validationService.validateSignIn(data, data);
        this._validationService.showErrors('.p-sign-in__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateSignIn(field, this.getFormData('.p-sign-in__form'));
        this._validationService.showErrors('.p-sign-in__form', errors);
    }
}